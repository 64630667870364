import React from "react";
import { useEffect, useState, Fragment } from "react";
import Pagination from "../shared/pagination/Pagination";
import { getAllDonor } from "../../actions/donor";
import PropsTypes from "prop-types";
import { connect } from "react-redux";

const ViewDonors = ({ getAllDonor, donors }) => {
  const [sponsor, setSponsor] = useState([]);
  useEffect(() => {
    getAllDonor();
  }, [getAllDonor]);

  console.log("Donor are", donors[0]);
  const [searchName, setSearchName] = useState("");

  const inputHandleChange = (e) => {
    setSearchName(e.target.value);
  };

  //Filter the search name
  const filterNames = donors.filter(
    (s) =>
      s.name.toLowerCase().includes(searchName.toLocaleLowerCase()) ||
      s.eventName.toLowerCase().includes(searchName.toLocaleLowerCase())
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;

  const currentPost = filterNames.slice(firstPostIndex, lastPostIndex);
  let totalDonation = 0;
  for (const key in filterNames) {
    totalDonation += filterNames[key].amount;
  }

  return (
    <Fragment>
      <section className="container">
        <div className="search-container">
          <h2>Total Donation: ${totalDonation} </h2>
          <h2>Search By Name</h2>
          <input
            type="text"
            placeholder="Search .."
            name="search"
            value={searchName}
            onChange={inputHandleChange}
          />
          <i className="fa fa-search"></i>
        </div>
        <h1 className="large text-primary">Manage Donors</h1>
        <table>
          <tr>
            <th>Donor Name</th>
            <th>Event Name</th>
            {/* <th>Action</th> */}
          </tr>

          {currentPost.map((s) => (
            <tr key={s.id}>
              <td>
                <i className="fas fa-user" /> {" " + s.name}
              </td>
              <td className={"text-success"}>{s.eventName}</td>
              {/* <td className="btn btn-danger">Delete</td> */}
            </tr>
          ))}
        </table>
      </section>

      <Pagination
        totalPage={donors.length}
        postPerPage={postPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Fragment>
  );
};

ViewDonors.PropsTypes = {
  getAllDonor: PropsTypes.func.isRequired,
  donors: PropsTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  donors: state.donorReducer.donors,
});

export default connect(mapStateToProps, { getAllDonor })(ViewDonors);
