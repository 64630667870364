import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
function ManageDonor() {
  return (
    <Fragment>
      <section className="container">
        <h1 className="large text-primary">Manage Donors</h1>
        <Link to="/admin/add-donor" className="btn btn-primary">
          Add Donor
        </Link>
        <Link to="/admin/view-donors" className="btn btn-primary">
          View Donors
        </Link>
      </section>
    </Fragment>
  );
}

export default ManageDonor;
