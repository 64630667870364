import {
  SAVE_DONOR_SUCCESS,
  SAVE_DONOR_FAILED,
  GET_DONORS,
  CLEAR_DONORS,
  DONOR_ERROR,
} from "../actions/types";

const initialState = {
  donor: null,
  loading: true,
  donors: [],
  error: {},
};

const donorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SAVE_DONOR_SUCCESS:
      return {
        ...state,
        donor: payload,
        loading: false,
      };
    case GET_DONORS:
      return {
        ...state,
        donors: payload,
        loading: false,
      };
    case SAVE_DONOR_FAILED:
      return {
        ...state,
        error: payload,
        loading: false,
        donor: null,
      };
    case DONOR_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        donor: null,
      };
    case CLEAR_DONORS:
      return {
        ...state,
        donors: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default donorReducer;
