




import React, { useEffect, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import QRCode from "react-qr-code";
import CTSLogo from "../../../src/assests/images/ctslogo.jpeg";
import RoseornamentDivider from "../../../src/assests/images/RoseornamentDivider.png";
import Psignature from "../../../src/assests/images/psignature.jpeg";
// import vpsignature from "../../../src/assets/images/vpsignature.jpeg";
import vpsignature from "../../../src/assests/images/vpsignature.jpeg";

import styles from "./Certificate.module.css";


const Certificate = () => {
  const qrCodeRef = useRef();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [certificateNumber, setCertificateNumber] = useState("");
  const [userPhoto, setUserPhoto] = useState(null);
  const [generationDate, setGenerationDate] = useState(""); // New state
       
  

  useEffect(() => {
    // Retrieve user data from localStorage
    const name = localStorage.getItem("userName");
    const email = localStorage.getItem("userEmail");
   

    if (!name || !email ) {
      // Redirect to login if no user data is found
      window.location.href = "/dashboard";
    } else {
      setUserName(name);
      setUserEmail(email);
          // Check if certificate number is already generated and stored for this user
      let storedCertificateNumber = localStorage.getItem(`certificateNumber_${email}`);
      if (!storedCertificateNumber) {
        // If not stored, generate a new certificate number for this user
        const firstLetter = name.charAt(0).toUpperCase();
        const randomDigits = Math.floor(100 + Math.random() * 900); // Random 3-digit number
        storedCertificateNumber = `${firstLetter}${randomDigits}`;
        // Store the generated certificate number in localStorage for this user (using email as key)
        localStorage.setItem(`certificateNumber_${email} `, storedCertificateNumber);
      }

      // Set the certificate number to the state
      setCertificateNumber(storedCertificateNumber);
    }
//Check if generation date is already stored
let storedDate = localStorage.getItem(`generationDate_${email}`);
if (!storedDate) {
  storedDate = new Date().toLocaleDateString(); // Save current date if not already stored
  localStorage.setItem(`generationDate_${email}`, storedDate);
}
setGenerationDate(storedDate);
  
  }, []);
    // Handle photo upload
  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUserPhoto(e.target.result); // Save base64 image to state
      };
      reader.readAsDataURL(file);
    }
  };

  // Automatically generate the certificate when the component is mounted


const generateCertificate = () => {
  const svg = qrCodeRef.current.querySelector("svg");

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const svgData = new XMLSerializer().serializeToString(svg);
  const img = new Image();

  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    const qrCodeImage = canvas.toDataURL("image/png");

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in", // Use inches for Letter size
      format: [8.5, 11], // US Letter dimensions in inches
    });
    
   


 

// Add a decorative border around the certificate
const margin = 0.5; // Margin around the border in inches

// Outer border
doc.setLineWidth(0.06); // Outer border thickness
doc.setDrawColor(255, 215, 0); // Bright golden color for the outer border
doc.rect(margin, margin, 11 - margin * 2, 8.5 - margin * 2, "S"); // Outer border for Letter size

// Inner border
doc.setLineWidth(0.04); // Inner border thickness
doc.setDrawColor(255, 223, 56); // Slightly lighter gold for the inner border
doc.rect(
  margin + 0.1,
  margin + 0.1,
  11 - (margin + 0.1) * 2,
  8.5 - (margin + 0.1) * 2,
  "S"
);

// Add certificate number
doc.setFont("arial", "bold");
doc.setFontSize(16);
doc.setTextColor("darkred");
doc.text(`Life Membership Number: ${certificateNumber}`, 2.5, 1.3, {
  align: "center",
});
//Add generation date
doc.setFontSize(16);
doc.setFont("arial", "bold");
doc.text(`Date: ${generationDate}`, 9.1, 1.3,{
  align: "center",
});

// Optional decorative border pattern
for (let i = 0; i < 4; i++) {
  doc.setLineWidth(0.01); // Very thin lines for the pattern
  doc.setDrawColor(255, 215 - i * 20, 0); // Gradual fade effect within the golden spectrum
  doc.rect(
    margin + 0.2 + i * 0.05,
    margin + 0.2 + i * 0.05,
    11 - (margin + 0.2 + i * 0.05) * 2,
    8.5 - (margin + 0.2 + i * 0.05) * 2,
    "S"
  );
}


      // Add logo
      doc.addImage(CTSLogo, "PNG", 4.7, 1, 1.2, 1); // Logo dimensions and position in inches
      //Add RoseornamentDivider
      doc.addImage(RoseornamentDivider, "PNG", 4.1,  2.3, 3.5, 3);

      // Add custom font
  const base64FontData = "AAEAAAASAQAABA..."; // Your Base64 font data here

//       // Register the custom font
 doc.addFileToVFS("CustomArtFont.ttf", base64FontData); // Replace with actual Base64 string
 doc.addFont("CustomArtFont.ttf", "CustomArt", "normal");

// Use the custom font
doc.setFont("arial", "bold"); // Use your art font
doc.setFontSize(28);
doc.setFont("times", "italic");
doc.setTextColor("darkred"); // Retain the blue color
doc.text("LIFE MEMBERSHIP CERTIFICATE", 5.5, 2.5, { align: "center" });


      // Certificate Body
      doc.setFontSize(27);
      doc.setFont("times", "italic");
      doc.text("This Certifies That", 5.5, 3.4, { align: "center" });

      doc.setFont("times", "bolditalic");
      doc.setFontSize(35);
      doc.setTextColor("darkgoldenrod");
      doc.text(userName, 5.5, 3.86, { align: "center" });
      

      doc.setFont("roboto", "italic");
      doc.setFontSize(20);
      doc.setTextColor("#004aad"); // Retain the blue color
      doc.text("has been accepted as a life member of the", 5.5, 4.2, {
        align: "center",
      });

      doc.setFont("arial", "bold");
      doc.setFontSize(16);
      doc.text("Chicagoland Tharu Society", 5.5, 4.5, { align: "center" });

      doc.setFont("arial", "italic", "bold");
      doc.setFontSize(17);
      doc.text(" Life Membership with the Chicagoland Tharu Society  honors \n a lifelong commitment  to preserving Tharu culture and fostering unity. ", 5.2, 5.2, { align: "center" });
     


// doc.setFont("kantipur", "italic", "bold");
//       doc.setFontSize(17);
//       doc.text(" चिकागोल्यान्ड थारू सोसाइटीको आजीवन सदस्यता\nथारू संस्कृतिको संरक्षण र एकताको प्रवर्धनप्रति आजीवन प्रतिबद्धतालाई सम्मान गर्दछ। ", 5.2, 5.2, { align: "center" });
     
 // Add overline above President
      const overlineText = "President";
      const textWidth = doc.getTextWidth(overlineText);
      const centerX = 6.75;
      
      doc.setLineWidth(0.015); // Thin border
      doc.setDrawColor(0, 0, 255)
      doc.line(centerX - textWidth / 2, 6.9, centerX + textWidth / 1.5, 6.9); // Overline
      doc.setFontSize(20);
      doc.text(overlineText, centerX, 7.2, { align: "center" });


      // Add overline above Vice-President
      const overlineText2 = "V-President";
      const textWidth2 = doc.getTextWidth(overlineText2);
      const centerX2 = 3.8;
      
      doc.setLineWidth(0.015); // Thin border
      doc.setDrawColor(0, 0, 255)
      doc.line(centerX2 - textWidth2 / 2, 6.9, centerX2 + textWidth2 / 1.5, 6.9); // Overline
      doc.setFontSize(20);
      doc.text(overlineText2, centerX2, 7.2, { align: "center" });

// Add user photo
if (userPhoto) {
  doc.addImage(userPhoto, "PNG", 1.1, 6, 1.4, 1.4); // Adjust position (x, y), width, height
}

         

// Define QR code dimensions and position
const qrX = 8.3; // X-coordinate for QR code
const qrY = 5.85;   // Y-coordinate for QR code
const qrWidth = 1.5; // Width of QR code
const qrHeight = 1.5; // Height of QR code

// Define padding around the QR code
const padding = 0.11; // Padding in inches
const borderRadius = 0.15; // Border radius in inches (~4px)

// Draw the QR code
doc.addImage(qrCodeImage, "PNG", qrX, qrY, qrWidth, qrHeight);


// Add a blue rounded border with padding
doc.setLineWidth(0.015); // Thin border
doc.setDrawColor(0, 0, 255); // Blue border color

// Draw rounded rectangle
doc.roundedRect(
  qrX - padding,             // X-coordinate with padding
  qrY - padding,             // Y-coordinate with padding
  qrWidth + padding * 2,     // Width with padding
  qrHeight + padding * 2,    // Height with padding
  borderRadius,              // Horizontal border radius
  borderRadius               // Vertical border radius
);

     doc.addImage(Psignature, "jpeg", 6.1, 6.4, 1.5, 0.5);

     
     

       doc.addImage(vpsignature, "jpeg", 3.2, 6.4, 1.5, 0.5);
    

      // Save certificate
      doc.save(`${userName}_Certificate.pdf`);
    };
  };

  return (
    <div className={styles.certificateContainer}>
      <h1 className={styles.welcomeHeader}>Welcome</h1>
      <h3 className={styles.welcomeMessage}> {userName} jee</h3>
      <h4 className={styles.welcomeFamily}>Welcome to the Family <span className={styles.exclamationFamily}>!</span> </h4>
     
          

<p className={styles.paraFirst}>
We are deeply honored to welcome you as a life member of the <strong><span className={styles.spanFamily}>Chicagoland Tharu Society</span></strong>. Your decision to join us reflects your commitment to preserving our heritage and fostering unity within our community.  
</p>

<p className={styles.paraSecond}>
Your generous support strengthens our mission to promote Tharu culture, traditions, and values for generations to come. Together, we can achieve great milestones and make a lasting impact. 
</p>
 
<p className={styles.paraThird}>
We sincerely appreciate your trust, encouragement, and dedication to our shared vision. Thank you for being an invaluable part of this journey. Let’s continue to grow and thrive as one family! 

</p>
 
<p className={styles.paraFourth}>
#ChicagolandTharuSociety #LifeMembership #ThankYou #CommunityPride #TogetherWeThrive</p>

      <div className="upload-section">
        <label htmlFor="photoUpload" className={styles.label}
       
        >
          Upload Your Photo:
        </label>
        <input
          
          type="file"
          id="photoUpload"
          accept="image/*"
          className={styles.uploadInput}
          onChange={handlePhotoUpload}
        />
      </div>

      <div display="none" ref={qrCodeRef} className="qr-code" style={{ display: "none" }}>
        <QRCode
         value={JSON.stringify({
          name: userName,
          email: userEmail,
          certificateNumber,
         
        })}
          size={128}
          level="H"
          fgColor="#004aad"
          bgColor="transparent"
        />
      </div>
      <h4 className={styles.qrMessage}>"कृपया तलको बटनमा क्लिक गरी आफ्नो प्रमाणपत्र सिर्जना गर्नुहोस्।"</h4>

     <button className={`btn ${styles.generateButton}`}  onClick={generateCertificate}>
        Generate Certificate
      </button>
      <p className={styles.paraFifth} >Note: </p> 
      <p className={styles.paraFifth}>
The Life Membership Certificate is exclusively available to **Life Members** of the Chicagoland Tharu Society.  </p>

<p className={styles.paraFifth}>We kindly request that only eligible Life Members proceed to generate this certificate. Any certificate generated without valid Life Membership will not be recognized or approved by the Chicagoland Tharu Society.  

Thank you for your understanding and cooperation.</p>
      
    </div>
  );
};

export default Certificate;




