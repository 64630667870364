import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import post from "./post";
import eventReducer from "./event";
import donor from "./donor";
import event from "./event";
import toggleMenu from "./togglemenu";
import donorReducer from "./donor";

export default combineReducers({
  alert,
  auth,
  profile,
  post,
  calendar: eventReducer,
  event,
  toggleMenu,
  donorReducer,
});
