import React from "react";
import { Fragment } from "react";
import "./Home.css";

const DonorItem = ({ name, eventName, amount, remark }) => {
  return (
    <Fragment>
      <div className="member-item bg-light">
        <div>
          <h6 className="text-center text-primary">{name}</h6>
        </div>

        <div>
          <h6>${amount}</h6>
        </div>
        <div>
          <h6>{eventName}</h6>
        </div>
        <div>
          <h6>{remark}</h6>
        </div>
      </div>
    </Fragment>
  );
};

export default DonorItem;
