import React from "react";
import { useState, Fragment } from "react";
import Alert from "../shared/Alert";
import { Link } from "react-router-dom";
import PropsTypes from "prop-types";
import { saveDonor } from "../../actions/donor";
import { connect } from "react-redux";
const AddDonor = ({ saveDonor, loading }) => {
  const [formData, setFormData] = useState({
    eventName: "",
    name: "",
    amount: "",
    remark: "",
  });

  const { eventName, name, amount, remark } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    saveDonor({ eventName, name, amount, remark });
  };

  return (
    <Fragment>
      <section className="container">
        <h1 className="large text-primary">Donor Detail</h1>
        <p className="lead">
          <i className="fas fa-user" /> Add Donor details
        </p>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <input
              type="text"
              placeholder="Add Event Name"
              name="eventName"
              autoComplete="eventName"
              value={eventName}
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Add Donor Name"
              name="name"
              value={name}
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              style={{ width: 20 + "em", height: 2.5 + "em" }}
              placeholder="Add Donation Amount"
              name="amount"
              value={amount}
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Remark"
              name="remark"
              value={remark}
              onChange={onChange}
            />
          </div>
          <input type="submit" className="btn btn-primary" value="Submit" />
          <Link to="/admin/donors" className="btn btn-primary">
            Cancel
          </Link>
        </form>

        <span>
          <Alert />
        </span>
      </section>
    </Fragment>
  );
};

AddDonor.PropsTypes = {
  saveDonor: PropsTypes.func.isRequired,

  loading: PropsTypes.object,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
});

export default connect(mapStateToProps, { saveDonor })(AddDonor);
