import axios from "axios";
import { setAlert } from "./alert";
import {
  SAVE_DONOR_SUCCESS,
  SAVE_DONOR_FAILED,
  GET_DONORS,
  CLEAR_DONORS,
  DONOR_ERROR,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import apiUrl from "../config";

// Save donor information
export const saveDonor =
  ({ eventName, name, amount, remark }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ eventName, name, amount, remark });

    try {
      const res = await axios.post(`${apiUrl}/api/donors`, body, config);

      dispatch({
        type: SAVE_DONOR_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert("Donor added Successfully", "success"));
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: SAVE_DONOR_FAILED,
      });
    }
  };

// Get all donors
export const getAllDonor = () => async (dispatch) => {
  try {
    const res = await axios.get(`${apiUrl}/api/donors`);

    dispatch({
      type: GET_DONORS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DONOR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
